//Cookies
export const CONTACT_ASSISTANT_COOKIE = 'NEXT_WEBSITE_CONTACT_ASSISTANT';
export const LOCALE_COOKIE = 'NEXT_LOCALE';

//Default Values
export const DEFAULT_CONTINENT = 'Europe';
export const DEFAULT_COUNTRY_NAME = 'Germany';
export const DEFAULT_COUNTRY_CODE = 'DE';
export const DEFAULT_GEOLOCATION = {
  countryName: 'Deutschland',
  countryCode: 'DE',
  zipcode: '49401',
  countryFlag: '/assets/img/flag_de.svg',
  latitude: '50.93790',
  longitude: '6.87910',
};
export const DEFAULT_LOCALE = 'de';
export const DEFAULT_MOBILE_PHONE = '+491714193635';
export const DEFAULT_PARTNER = {
  address: 'Hunteburger Str. 32, 49401 Damme',
  company_name: 'GRIMME Landmaschinenfabrik GmbH & Co. KG',
  email: 'mygrimme@grimme.de',
  featured_partner_title: 'GRIMME Landmaschinenfabrik',
  location_image: 'https://cdn.buttercms.com/ye1Map2pTi2ri5OELxkP',
  region: 'de',
  telefax: '+49 5491 666-2297',
  telephone: '+49 5491 666-0',
};

//Regular Expressions
export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX =
  //@ts-expect-error: This regex is ok
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u; //matches international names
export const MACHINE_SERIAL_NUMBER_REGEX = /^[0-9]{8}$/;
export const PUBLIC_FILE_REGEX = /\.(.*)$/;

//Other
//TODO: Move to enums file
export enum MediaQueries {
  BIG_DESKTOP = '(min-width:1600px)',
  DESKTOP = '(min-width:960px)',
  TABLET = '(max-width:960px)',
  MOBILE = '(max-width:600px)',
}
export const NEWS_CATEGORY = 'product';
export const DAY_IN_SECONDS = 86400;
