import {
  AppConfigurationClient,
  ConfigurationSetting,
  FeatureFlagValue,
  parseFeatureFlag,
} from '@azure/app-configuration';
import { environment } from '@/environment';

interface AudienceParameters {
  type: 'audience';
  Audience: {
    DefaultRolloutPercentage: number;
    Users: string[];
    Groups: {
      name?: string;
      DefaultRolloutPercentage?: string;
    };
  };
}

export class AzureAppConfiguration {
  static azureClient = new AppConfigurationClient(
    `Endpoint=${environment.azureAppConfigEndpoint}`,
  );

  static fetchAllFeatureFlags = async (
    label?: 'development' | 'production',
  ) => {
    try {
      let nextPageCursor: string | undefined;
      const result: ConfigurationSetting<FeatureFlagValue>[] = [];

      const iterator = this.azureClient.listConfigurationSettings({
        labelFilter: label,
      });

      for await (const page of iterator.byPage({
        continuationToken: nextPageCursor,
      })) {
        nextPageCursor = page.continuationToken;

        for (const setting of page.items) {
          result.push(parseFeatureFlag(setting));
        }
      }

      return result;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  static composeFullFeatureFlagKey = (flagKey: string) => {
    return `.appconfig.featureflag/${flagKey.toString().trim()}`;
  };

  /**
   * Retrieves the specified feature flag from Azure App Configuration.
   * @param {string} flagKey App Config Feature Flag key
   * @returns the feature flag for the specified key
   */

  static fetchFeatureFlag = async (
    flagKey: string,
    label?: 'development' | 'production',
  ): Promise<ConfigurationSetting<FeatureFlagValue> | undefined> => {
    try {
      const result = await this.azureClient.getConfigurationSetting({
        key: this.composeFullFeatureFlagKey(flagKey),
        label,
      });

      return parseFeatureFlag(result);
    } catch (error) {
      console.error(error);
    }
  };

  static applyTargetFilters = (
    azureFilters: FeatureFlagValue['conditions']['clientFilters'][number],
    flagFilters: object,
    useRolloutPercentage: boolean,
  ): boolean => {
    let filtersMatch = false;

    const audience = azureFilters?.parameters?.Audience as
      | AudienceParameters['Audience']
      | undefined;
    const rolledOutForEveryone =
      useRolloutPercentage && audience?.DefaultRolloutPercentage === 100;

    for (const [key, value] of Object.entries(flagFilters)) {
      switch (key) {
        case 'id':
        case 'email': {
          const emailWhitelisted = !!audience?.Users?.includes(value);

          filtersMatch = emailWhitelisted;
          break;
        }
      }
    }

    return rolledOutForEveryone || filtersMatch;
  };

  static evaluateFilters = (
    azureFilters: FeatureFlagValue['conditions']['clientFilters'],
    flagFilters: object,
    useRolloutPercentage: boolean = true,
  ): boolean => {
    return azureFilters.every((filter) => {
      switch (filter.name) {
        case 'Microsoft.Targeting':
          return this.applyTargetFilters(
            filter,
            flagFilters,
            useRolloutPercentage,
          );
        default:
          return null;
        // cases for all other type of azure filter conditions can go here
      }
    });
  };
}
