// TODO: create @grimme/hooks and move to there
import { environment } from '@/environment';
import { Geolocation } from '@grimme/types';
import axios from 'axios';

export const fetchGeolocation = async (
  language: string,
): Promise<Geolocation> => {
  const threeDays = 1000 * 60 * 60 * 24 * 3;
  const curLocation = localStorage.getItem('geolocation');
  const lastFetchedDate = localStorage.getItem('geolocation-date');

  if (curLocation && Number(lastFetchedDate) + threeDays > Date.now()) {
    return JSON.parse(curLocation);
  }

  const location: Geolocation = (
    await axios(environment.geolocationApi, {
      params: {
        language,
      },
    })
  ).data;

  localStorage.setItem('geolocation', JSON.stringify(location));
  localStorage.setItem('geolocation-date', Date.now().toFixed(0));

  return location;
};
