import { CtasSectionButtonsProps } from '@grimme/components/lib/ctas-section';
import { CtaSectionButtonData } from '@/lib/butter-types/pages/landing-page';
import {
  CountriesListData,
  PartnersCountriesPerContinent,
} from '@/lib/butter-types/pages/partners-page';

export const parseCountriesFromContinents = (
  continents: PartnersCountriesPerContinent,
): CountriesListData[] =>
  // TODO: Fix these TS errors
  Object.keys(continents)
    // @ts-expect-error: continents[key] is not an array
    .reduce((acc, key) => [...acc, ...continents[key]], [])
    // @ts-expect-error: countries is not an array
    .sort((a, b) => a.country_code.localeCompare(b.country_code));

export const parseCtaSectionButtons = (
  buttons: CtaSectionButtonData[],
): CtasSectionButtonsProps[] => {
  return buttons.map((button, index) => ({
    id: String(index),
    label: button.label,
    props: parseButtonProps({ ...button }),
  }));
};

const parseButtonProps = (button: {
  label: string;
}): Omit<CtasSectionButtonsProps, 'label'> => {
  // @ts-expect-error: label is not a valid prop
  delete button.label;
  // @ts-expect-error: we're returning a button object without the label prop
  return button;
};
