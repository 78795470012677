// @ts-expect-error: TypeScript cannot find module '@grimme/next-grimme-auth'
import { useSession } from '@grimme/next-grimme-auth';
import axios from 'axios';
import { QueryFunction, useQuery } from 'react-query';
import { IUser } from './types';
import { environment } from '@/environment';

const getProfileData: QueryFunction<IUser, ['profile', string]> = async ({
  queryKey: [_, accessToken],
}): Promise<IUser> => {
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const response = await axios.get(`${environment.gridApi}/api/v1/user/me`, {
    headers,
  });
  return response.data;
};

export const useProfile = () => {
  const { data, status } = useSession();
  const isAuthenticated = status === 'authenticated';

  return useQuery(['profile', data?.accessToken], getProfileData, {
    enabled: data != null && isAuthenticated,
  });
};
