// TODO: create @grimme/hooks and move to there
import { DEFAULT_GEOLOCATION, fetchGeolocation } from '@/utils';
import { Geolocation } from '@grimme/types';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export const useGeolocation = (defaultLocation = DEFAULT_GEOLOCATION) => {
  const [geolocation, setGeolocation] = useState<Geolocation>(defaultLocation);
  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const geolocation: Geolocation = await fetchGeolocation(language);
        if (isMounted) {
          setGeolocation(geolocation);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [language]);

  return geolocation;
};
