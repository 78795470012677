import React, { ReactNode } from 'react';
import { useProfile } from '@/hooks';
import { FeatureFlagsContext, useFeatureFlagsFetch } from '@/hooks/feature-flags';

export function FeatureFlagsProvider(props: { children: ReactNode }) {
  const { children } = props;
  const { data: profile } = useProfile();
  const { featureFlags, isLoading } = useFeatureFlagsFetch([profile?.id]);

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags, isLoading }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
