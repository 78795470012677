import { useContext, useMemo } from 'react';
import { FeatureFlagsContext } from './feature-flags-context';
import { environment } from '@/environment';
import { AzureAppConfiguration } from '@/lib/azure-app-configuration/azure-app-configuration';
import { FEATURE_FLAGS } from '@/lib/azure-app-configuration';

export const useFeatureFlag = (
  flagKey: FEATURE_FLAGS,
  customFilters?: object,
): {
  enabled: boolean | undefined;
  isLoading: boolean;
} => {
  const { featureFlags, isLoading } = useContext(FeatureFlagsContext);

  const enabled = useMemo<boolean | undefined>(() => {
    if (isLoading) {
      return undefined;
    }

    const labelFilter = environment.environment === 'production' ? 'production' : 'development';

    const targetFeatureFlag = featureFlags.find(
      (featureFlag) =>
        featureFlag.key ===
          AzureAppConfiguration.composeFullFeatureFlagKey(flagKey) &&
        (featureFlag.label ? featureFlag.label === labelFilter : true),
    );

    if (!targetFeatureFlag) {
      return undefined;
    }

    if (targetFeatureFlag.value.enabled === false) {
      return false;
    }

    if (customFilters) {
      const azureFilters = targetFeatureFlag.value.conditions.clientFilters;

      return AzureAppConfiguration.evaluateFilters(azureFilters, customFilters);
    }

    return targetFeatureFlag.value.enabled;
  }, [isLoading, featureFlags, customFilters, flagKey]);

  return { enabled, isLoading };
};
