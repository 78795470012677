import {
  ConfigurationSetting,
  FeatureFlagValue,
} from '@azure/app-configuration';
import { createContext } from 'react';

export type FeatureFlagsContextStructure = {
  featureFlags: ConfigurationSetting<FeatureFlagValue>[];
  isLoading: boolean;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextStructure>({
  featureFlags: [],
  isLoading: true,
});
