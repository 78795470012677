import { useEffect, useState } from 'react';
import {
  ConfigurationSetting,
  FeatureFlagValue,
} from '@azure/app-configuration';
import { AzureAppConfiguration } from '@/lib/azure-app-configuration/azure-app-configuration';

export const useFeatureFlagsFetch = (dependencies: unknown[] = []) => {
  const [featureFlags, setFeatureFlags] = useState<
    ConfigurationSetting<FeatureFlagValue>[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    AzureAppConfiguration.fetchAllFeatureFlags().then((responseFlags) => {
      setIsLoading(false);
      setFeatureFlags(responseFlags);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return { featureFlags, isLoading };
};
