const HttpBackend = require('i18next-http-backend/cjs');
const locales = require('./gen/locales.json');

module.exports = {
  i18n: {
    // Introduce a "default" locale to use as default.
    // If we get a request on that "default" locale, we can detect
    // it in the _middleware.ts and redirect to the correct locale.
    //
    // This allows us to always have the locale in the url.
    // For more info, see _middleware.ts
    locales: ['default', ...locales.map((v) => v.value)],
    defaultLocale: 'default',
    //if it does not work, set it to false and implement custom solution in _middleware.ts
    localeDetection: true,
  },
  ns: ['myGRIMME'],
  defaultNS: 'myGRIMME',
  backend: {
    loadPath: `${process.env.NEXT_PUBLIC_TRANSLATIONS_API}/translations/{{ns}}?language={{lng}}`,
    reloadInterval: typeof window !== 'undefined' ? false : 60 * 60 * 1000, // Reload translations every hour on server
  },
  serializeConfig: false,
  use: typeof window !== 'undefined' ? [] : [HttpBackend], // Don't load translations on client
};
