import { useGeolocation } from '../useGeolocation';
import { useProfile } from '../useProfile';

export const useUserCountryCode = () => {
  const { data: profile } = useProfile();
  const countryCodeFromProfile = profile?.company?.address?.countryCode;
  const { countryCode: countryCodeFromGeolocation } = useGeolocation();
  if (!countryCodeFromProfile && !countryCodeFromGeolocation) return undefined;
  return countryCodeFromProfile || countryCodeFromGeolocation;
};
