'use client';
import { environment } from '@/environment';
import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider,
  withNotificationsReducer,
} from '@grimme/components';
import { AnalyticsProvider, useUserCentricsConsent } from '@grimme/gdap';
// @ts-expect-error: TypeScript cannot find module '@grimme/next-grimme-auth'
import { useSession } from '@grimme/next-grimme-auth';
import theme from '@grimme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { configureStore } from '@reduxjs/toolkit';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { FeatureFlagsProvider } from './feature-flags-provider';

const store = configureStore({
  reducer: withNotificationsReducer({}),
});

export function Providers({
  children,
}: {
  children?: React.ReactElement[] | React.ReactElement;
}) {
  const { commitHash, insightsKey, projectName, appVersion } = environment;
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const [queryClient] = useState(() => new QueryClient());

  const { data } = useSession();
  const isAiConsentGiven = useUserCentricsConsent('Azure Application Insights');

  // TODO: Remove as soon as the theme is updated with the new styles.
  const websiteTheme = theme;
  websiteTheme.palette.background.default = theme.palette.grey[100];

  return (
    <ComponentsConfigProvider t={t} language={locale}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={{}}>
            <ThemeProvider theme={websiteTheme}>
              <CssBaseline />
              <AnalyticsProvider
                instrumentationKey={insightsKey}
                isConsentGiven={isAiConsentGiven}
                defaultEnvelopeData={{
                  commit: commitHash,
                  project: projectName,
                  version: appVersion,
                }}
                username={data?.user?.email}
              >
                <GrimmeComponentsProvider>
                  <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
                </GrimmeComponentsProvider>
              </AnalyticsProvider>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </ReduxProvider>
    </ComponentsConfigProvider>
  );
}
