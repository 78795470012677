import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// We don't export withServerSideTranslations here because it uses 'fs' as a dependency. So it can only be executed in a server environment.
export * from './consts';
export * from './countries';
export * from './geolocation';
export * from './parsers';
